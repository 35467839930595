// ~/stores/ridesStore.ts
import { defineStore } from "pinia";
import _ from "lodash";
import type { ITrip, ISection, ITripWithLocation } from "~/../backend/types/trip";
import type {
    ITripLocationStringified,
    ITripHistoryDetail,
    ITripHistoryLocation
} from "~/../backend/types/tripHistory";
import { format } from "date-fns";
import type { ITerminal } from "~/types/terminal";
import type { IDispoSlot } from "~/types/slot";

export interface ISectionView {
    id: number;
    content: string;
}

export interface RidesStore {
    loading: boolean;
    active: Array<ITripWithLocation>;
    filteredRides: Array<ITripWithLocation>;
    slots: Array<IDispoSlot>;
    sections: Array<ISectionView>;
    locationFocus: Array<ITripHistoryLocation>;
    locationFocusTrip?: ITripHistoryDetail;
}

export const useRidesStore = defineStore("ridesStore", {
    state: (): RidesStore => ({
        loading: false,
        active: [],
        filteredRides: [],
        slots: [],
        sections: [],
        locationFocus: [],
        locationFocusTrip: undefined
    }),
    actions: {
        async initializeActive(depo: ITerminal) {
            this.loading = true;
            const { data: rides } = await useAxios().get<Array<ITripWithLocation>>(`api/ride/active/${depo.ST_FLAG}`);
            this.active = _.orderBy(rides, "DISTANCE", ["asc"]);
            this.filteredRides = this.active;
            this.loading = false;
        },
        initializeSections(sectionsArray: Array<ISection>) {
            this.sections = sectionsArray.map((section) => ({
                id: section.ID,
                content: section.POPIS
            }));
        },
        initializeSlots(slotsArray: Array<IDispoSlot>) {
            this.slots = slotsArray;
        },
        updateRide(ride: ITripWithLocation | undefined) {
            if (ride) {
                const finding = this.active.findIndex((active) => active.ID === ride.ID);
                if (finding >= 0) {
                    if (!this.active[finding].LOCATION) {
                        this.active[finding].LOCATION = [];
                    }
                    ride.LOCATION = this.active[finding].LOCATION;
                    ride.TELEFON = this.active[finding].TELEFON;
                    ride.UPDATED_ON = format(new Date(), "yyyy-MM-dd HH:mm:ss");
                }
            }
        },
        addRide(ride: ITripWithLocation) {
            this.active.push(ride);
        },
        removeRide(ride: ITrip) {
            const finding = this.active.findIndex((active) => active.ID === ride.ID);

            if (finding >= 0) {
                this.active.splice(finding, 1);
            }
        },
        addSlot(slot: IDispoSlot) {
            this.slots.push(slot);
        },
        addLocation(location: ITripLocationStringified) {
            const finding = this.active.findIndex((active) => active.ID === parseInt(location.ID_TRIP));
            if (finding >= 0 && this.active[finding]) {
                this.active[finding].DISTANCE = parseInt(location.DISTANCE);
                if (this.active[finding] && !this.active[finding].LOCATION) {
                    this.active[finding].LOCATION = [];
                }
                this.active[finding].LOCATION.push({
                    ACC: parseInt(parseInt(location.ACC).toFixed(2)),
                    ID: parseInt(location.ID),
                    ID_TRIP: parseInt(location.ID_TRIP),
                    LAT: parseFloat(parseFloat(location.LAT.replace(/,/g, ".")).toFixed(6)),
                    LNG: parseFloat(parseFloat(location.LNG.replace(/,/g, ".")).toFixed(6)),
                    TIME: location.TIME
                });

                this.filteredRides = _.orderBy(this.filteredRides, "DISTANCE", ["asc"]);
            }
        },
        removeSlot(slot: number) {
            for (const slotArray of this.slots) {
                if (slotArray.trips && slotArray.trips.length === 1 && slotArray.trips[0] === slot) {
                    this.slots.splice(
                        this.slots.findIndex((i) => i === slotArray),
                        1
                    );
                    break;
                }

                if (slotArray.trips) {
                    const index = slotArray.trips.indexOf(slot);
                    if (index !== -1) {
                        slotArray.trips.splice(index, 1);
                        slotArray.content = slotArray.trips.length.toString();
                        break;
                    }
                }
            }
        },
        filterRides(rides: Array<ITripWithLocation>) {
            this.filteredRides = _.orderBy(rides, "DISTANCE", ["asc"]);
        },
        setLoading(state: boolean) {
            this.loading = state;
        },
        setLocationFocus(state: Array<ITripHistoryLocation>) {
            this.locationFocus = state;
        },
        setLocationFocusTrip(state: ITripHistoryDetail | undefined) {
            this.locationFocusTrip = state;
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useRidesStore, import.meta.hot));
}
